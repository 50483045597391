import 'flickity/dist/flickity.pkgd'
import vidim from 'vidim/dist/vidim'

window.vidim = vidim

var splashSlider = jQuery('.splash__holder'),
    splashSlides = jQuery('.splash__holder .splash')

let splashVideoPrev

if (splashSlider.length > 0 && splashSlides.length > 1) {

    splashSlider.flickity({
        cellSelector    : '.splash',
        prevNextButtons : false,
        wrapAround      : true,
        pageDots        : true,
        cellAlign       : 'left',
    })

    setTimeout(function () {
        splashSlider.flickity('select', 0)
    }, 3000)

    let splashSliderData = splashSlider.data('flickity')

    splashSlider.on('select.flickity', function () {
        if (splashVideoPrev !== undefined) {
            splashVideoPrev.pause()
        }

        if ( jQuery(splashSliderData.selectedElement).hasClass('splash--video') ) {

            splashVideoPrev = window[`splash${splashSliderData.selectedIndex}`]
            window[`splash${splashSliderData.selectedIndex}`].play()

        }
    })
}
